<template>
  <div>
    <v-form>
      <v-card>
        <v-dialog
          v-model="isMedicationSearchFormActive"
          persistent
          max-width="50%"
        >
          <v-card id="med-select">
            <div v-if="isMedicationSearchFormActive">
              <v-toolbar>
                <v-btn
                  color="error"
                  @click="closeForm()"
                >
                  Close
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  @click="fetchRXNormItems()"
                >
                  Search
                </v-btn>

              </v-toolbar>
            </div>

            <v-card-subtitle>
              <v-row>
                <v-col
                  cols="7"
                >
                <v-text-field
                  v-model="searchQuery"
                  clearable
                  flat
                  :prepend-inner-icon="icons.mdiMagnify"
                  label="Search"
                >
                </v-text-field>
                </v-col>

                <v-col
                  cols="5"
                >
                  <v-text-field
                    v-model="doseQuery"
                    clearable
                    flat
                    :prepend-inner-icon="icons.mdiNeedle"
                    hint="100 MG, 100 UNT/ML, 1 ML, 0.05 MG/ACTUAT, 1 MG/ML"
                    label="Dose"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>


            <v-card v-if="rxMedicationListTable !== null && rxMedicationListTable.length !== 0">
              <v-card-title
                justify="space-around"
              >
                Drug List
              </v-card-title>
              <v-card-text>
                <v-List>
                  <v-list-item-group
                    color="primary"
                  >
                    <template
                      v-for="item in rxMedicationListTable"
                    >
                      {{ item.tty }}
                      <v-list-item
                        v-for="(rxcui, index) in item.conceptProperties"
                        :key="index.index"
                        :rxcui="rxcui"
                      >
                        <v-list-item-content
                          v-model="selectedRow"
                          v-bind="rxcui"
                          @click="validateNDC(rxcui), closeForm()"
                        >
                          {{ rxcui.rxcui }}
                          {{ rxcui.name }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-List>
              </v-card-text>
              </v-card>
          </v-card>
        </v-dialog>

        <div
          v-if="isDialogVisible"
        >
          <v-card>
            <medication-add-edit
              v-if="isDialogVisible"
              :is-medication-form-active="isDialogVisible"
              :patient-script-d-t-o="selectedItem"
              :is-edit="true"
              @update-dialog="updateDialog"
            ></medication-add-edit>
          </v-card>
        </div>
      </v-card>
    </v-form>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'
import { mdiCloudPrintOutline, mdiSendOutline, mdiMagnify, mdiNeedle } from '@mdi/js'
import MedicationAddEdit from '@/views/medication/MedicationAddEdit.vue'

export default {
  components: { MedicationAddEdit },
  props: {
    isMedicationSearchFormActive: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: String,
      default: null,
      required: false,
    },
  },
  setup(props, { emit }) {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const {
      rxMedicationListTable,
      ndcMedicationListTable,
      tableColumns,
      searchQuery,
      doseQuery,
      loading,
      options,
      selectedRow,

      fetchRXNormItems,
      fetchOpenFDAItems,
      medTimeList,
      fetchSiteMedTimes,
    } = useMedicationList()

    const selectedMed = ref('')

    const log = msg => {
      //console.log(msg)
    }

    const close = () => {
      emit('update-dialog', 'search')
    }

    const isDialogVisible = ref(false)
    const selectedItem = ref()
    const editedIndex = ref()
    const isSearchFormActive = props.isMedicationSearchFormActive

    return {
      medTimeList,
      selectedMed,
      fetchSiteMedTimes,

      required,
      log,

      isDialogVisible,
      ndcList: [],
      rxMedicationListTable,
      ndcMedicationListTable,
      tableColumns,
      searchQuery,
      loading,
      options,
      selectedRow,

      fetchRXNormItems,
      fetchOpenFDAItems,

      selectedItem,
      editedIndex,
      doseQuery,

      close,
      isSearchFormActive,

      icons: {
        mdiSendOutline,
        mdiCloudPrintOutline,
        mdiMagnify,
        mdiNeedle,
      },
    }
  },
  methods: {
    closeForm() {
      this.close()
    },
    validateNDC(rx) {
      //console.log('Selected RX', rx)

      this.ndcMedicationListTable = rx.openFDAItemDTO.results
      console.log('NDC LIST:', this.ndcMedicationListTable)
      if (this.ndcMedicationListTable != null && this.ndcMedicationListTable.length > 0) {
        this.editItem(rx, this.ndcMedicationListTable[0])
      }

      /*
      this.fetchOpenFDAItems(rx.rxcui)

      this.$watch('ndcMedicationListTable', () => {
        //console.log('NDC LIST:', this.ndcMedicationListTable)
        if (this.ndcMedicationListTable != null && this.ndcMedicationListTable.length > 0) {
          this.editItem(rx, this.ndcMedicationListTable[0])
        }
      })
      */
    },

    editItem(rx, ndc) {
      this.editedIndex = this.rxMedicationListTable.indexOf(rx)

      const patientScriptDTO = {}
      patientScriptDTO.drugName = rx.name
      patientScriptDTO.rxcui = rx.rxcui

      patientScriptDTO.genericName = ndc.generic_name
      patientScriptDTO.rxcui = rx.rxcui
      patientScriptDTO.dEASchedule = ndc.dea_schedule == null ? 'N/A' : ndc.dea_schedule

      const strength = []
      ndc.active_ingredients.forEach(ingredient => {
        strength.push(ingredient.strength)
      })
      patientScriptDTO.drugStrength = strength.join('/')

      const packs = []
      ndc.packaging.forEach(pack => {
        packs.push(pack)
      })
      patientScriptDTO.packs = packs

      const routes = []
      ndc.route.forEach(path => {
        routes.push(path)
      })
      patientScriptDTO.routes = routes

      patientScriptDTO.drugForm = ndc.dosage_form

      patientScriptDTO.patientId = this.patientId == null ? null : this.patientId

      this.selectedItem = patientScriptDTO
      this.updateDialog('Add')
    },

    updateDialog(status) {
      if (status == null) {
        this.selectedItem = {}
      }
      this.isDialogVisible = !this.isDialogVisible
      this.isSearchFormActive = !this.isSearchFormActive
    },
  },
}
</script>
