<template>
  <div id="patient-med-list">
    <!-- dialog -->

    <div
      v-if="isNew"
    >
      <v-card>
        <medication-select
          :is-medication-search-form-active="isMedicationSearchFormActive"
          :patient-id="patientData.id"
          @update-dialog="updateDialog"
        ></medication-select>
      </v-card>
    </div>

    <div
      v-else
    >
      <v-card>
        <medication-add-edit
          v-if="renderDialog"
          :is-medication-form-active="renderDialog"
          :patient-script-d-t-o="selectedItem"
          :is-edit="isEdit"
          @update-dialog="updateDialog"
        ></medication-add-edit>
      </v-card>
    </div>

    <v-card>
      <v-card-title> Patient Scripts</v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          max-width="20px"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="form-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="renderDialog"
            color="primary"
            class="mb-4 me-3"
            @click="updateDialog('search'), isEdit = true, isNew = true"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Medication</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedItem"
        :headers="patientScriptTableColumns"
        :items="scriptList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
        group-by="isActive"
        :group-desc="true"
      >
        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
            <td :colspan="headers.length">
                <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen"> {{icons.mdiMinus}}</v-icon>
                    <v-icon v-else>{{icons.mdiPlus}}</v-icon>
                </v-btn>
                Active - {{ group }}
            </td>
        </template>

        <template #[`item.isActive`]="{item}">
          {{ readStatus(item.isActive) }}
        </template>
        <template #[`item.distributionSchedule.startDate`]="{item}">
          {{ formatDate(item.distributionSchedule.startDate) }}
        </template>
        <template #[`item.distributionSchedule.stopDate`]="{item}">
          {{ formatDate(item.distributionSchedule.stopDate) }}
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="updateDialog('display'), isEdit = false, renderForm(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="updateDialog('display'), isEdit = true, renderForm(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="updateScriptStatus(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>{{ item.isActive ? 'Disable' : 'Activate' }}</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiMinus,
} from '@mdi/js'

import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'
import CommonList from '@/store/CommonService'
import MedicationSelect from '@/components/medication/MedicationSelect.vue'
import MedicationAddEdit from '@/views/medication/MedicationAddEdit.vue'

export default {
  components: { MedicationSelect, MedicationAddEdit },
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
    }
  },
  setup() {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const {
      postPatientScript,
      fetchPatientScripts,
      scriptList,
      loading,
      searchQuery,
      patientScriptTableColumns,
      resolveFormStatusVariantAndIcon,
      options,
      statusFilter,
      formData,
      groupFilter,
    } = useMedicationList()

    const { readStatus, formatDate } = CommonList()

    const isEdit = ref(false)
    const isNew = ref(false)
    const selectedItem = ref(null)
    const renderDialog = ref(false)
    const isMedicationSearchFormActive = ref(false)

    return {
      searchQuery,
      statusFilter,
      loading,
      options,
      renderDialog,
      selectedItem,
      isEdit,
      isNew,
      formData,
      resolveFormStatusVariantAndIcon,
      groupFilter,

      patientScriptTableColumns,
      fetchPatientScripts,
      postPatientScript,
      scriptList,

      readStatus,
      formatDate,

      isMedicationSearchFormActive,

      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiMinus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    if (this.patientData != null || this.patientData !== undefined) {
      this.fetchPatientScripts(this.patientData.id)
    }
    else if (this.patientId != null && this.patientId !== undefined) {
      this.fetchPatientScripts(this.patientId)
    }

    this.$watch('scriptList', () => {
      Object.keys(this.$refs).forEach(k => {
        if (k == "false") {
            this.$refs[k].$el.click()
        }
      })
    })
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item

      // console.log(this.selectedItem)
    },
    updateScriptStatus(item) {
      this.selectedItem = item
      this.selectedItem.isActive = !item.isActive

      // console.log(this.selectedItem)
      this.postPatientScript(this.selectedItem)
    },
    submitDelete(formDTO) {
      this.deleteForm(formDTO)
    },
    fetchData() {
      this.fetchPatientScripts(this.patientId)
      this.$watch('scriptList', () => {
      })
    },
    log(msg) {
      // console.log(msg)
    },
    renderForm(formData) {
      this.selectedItem = formData
    },
    updateType(item) {
      this.groupFilter = []
      if (item !== null) {
        if (!this.groupFilter.includes(item.display)) {
          this.groupFilter.push(item.display)
        }
      }

      // console.log('Group', this.groupFilter)
      this.fetchPatientScripts(this.patientId)
      this.$watch('scriptList', () => {
      })
    },
    updateDialog(dialog) {
      // console.log(this.renderDialog)
      if (dialog === 'display' && this.renderDialog) {
        this.selectedItem = null
      }
      if (dialog === 'search') {
        this.isMedicationSearchFormActive = !this.isMedicationSearchFormActive
        this.selectedItem = { patientId: this.patientData.id }
      }
      this.renderDialog = !this.renderDialog
    },
  },
}
</script>
